import React, { useState } from "react";
import GeneratorTitleBlock from "../GeneratorTitleBlock/GeneratorTitleBlock";
import CodePreview from "../../../../components/CodePreview/CodePreview";
import ColumnsGenerator from "../../../../components/FormComponent/ColumnsGenerator";

const Column = () => {
	const [codeString, setCodeString] = useState("");
	return (
		<>
			<GeneratorTitleBlock title="Columns" />

			<div className="generator-area">
				<div className="generator-area-options half-width">
					<h6 className="h6 pb-2">Options</h6>
					<div className="option-box card card--custom mb-4">
						<div className="card-body form-wrap">
							<ColumnsGenerator
								codeString={codeString}
								setCodeString={setCodeString}
							/>
						</div>
					</div>
				</div>
				<div className="generator-area-preview half-width">
					<CodePreview data={codeString} />
				</div>
				<div className="generator-area-preview full-width">
					<h6 className="h6 pb-2">Preview</h6>
					<div className="option-box card card--custom mb-4">
						<div className="card-body">
							<style
								// eslint-disable-next-line react/no-danger
								dangerouslySetInnerHTML={{
									__html: `
							.column-box { ${codeString} }
						`,
								}}
							/>
							<div className="color-preview-box  column-box">
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat. Duis aute
									irure dolor in reprehenderit in voluptate velit esse cillum
									dolore eu fugiat nulla pariatur. Excepteur sint occaecat
									cupidatat non proident, sunt in culpa qui officia deserunt
									mollit anim id est laborum. Lorem ipsum dolor sit amet,
									consectetur adipiscing elit, sed do eiusmod tempor incididunt
									ut labore et dolore magna aliqua. Ut enim ad minim veniam,
									quis nostrud exercitation ullamco laboris nisi ut aliquip ex
									ea commodo consequat. Duis aute irure dolor in reprehenderit
									in voluptate velit esse cillum dolore eu fugiat nulla
									pariatur. Excepteur sint occaecat cupidatat non proident, sunt
									in culpa qui officia deserunt mollit anim id est laborum.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default Column;
