/* eslint-disable eqeqeq */
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import InputNumber from "rc-input-number";
import Select from "react-select";

import { transitionproperty } from "../../Data/transitionproperty";

const TransitionGenerator = ({ className, setCodeString }) => {
	const [property, setProperty] = useState("all");
	const [timing, setTiming] = useState("ease-in-out");
	const [duration, setDuration] = useState(0.3);
	const timingOption = [
		{ value: "ease", label: "Ease" },
		{ value: "linear", label: "Linear" },
		{ value: "ease-in", label: "Ease In" },
		{ value: "ease-out", label: "Ease Out" },
		{ value: "ease-in-out", label: "Ease In Out" },
		{ value: "step-start", label: "Step Start" },
		{ value: "step-end", label: "Step End" },
	];

	console.log(transitionproperty);

	setCodeString(`-webkit-transition: ${property} ${duration}s ${timing};
-moz-transition: ${property} ${duration}s ${timing};
transition: ${property} ${duration}s ${timing};`);

	return (
		<div className={`${className}`}>
			<div className="row">
				<div className="col-md-4">
					<div className="form-group">
						<Form.Label>Property</Form.Label>
						<Select
							classNamePrefix="select"
							options={transitionproperty}
							defaultValue={transitionproperty[0].options[0]}
							menuPosition="fixed"
							isSearchable={false}
							onChange={(e) => setProperty(e.value)}
						/>
					</div>
				</div>
				<div className="col-md-4">
					<div className="form-group">
						<Form.Label>Duration (sec)</Form.Label>
						<InputNumber
							min={0}
							step={0.1}
							onChange={(e) => setDuration(e)}
							value={duration}
						/>
					</div>
				</div>
				<div className="col-md-4">
					<div className="form-group">
						<Form.Label>Timing Function</Form.Label>
						<Select
							classNamePrefix="select"
							options={timingOption}
							defaultValue={timingOption[4]}
							menuPosition="fixed"
							isSearchable={false}
							onChange={(e) => setTiming(e.value)}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TransitionGenerator;
