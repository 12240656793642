/* eslint-disable import/prefer-default-export */
export const backgroundposition = [
	{ value: "center center", label: "center center" },
	{ value: "center bottom", label: "center bottom" },
	{ value: "center top", label: "center top" },
	{ value: "left center", label: "left center" },
	{ value: "left bottom", label: "left bottom" },
	{ value: "left top", label: "left top" },
	{ value: "right center", label: "right center" },
	{ value: "right bottom", label: "right bottom" },
	{ value: "right top", label: "right top" },
];

export const backgroundsize = [
	{ value: "auto", label: "auto" },
	{ value: "contain", label: "contain" },
	{ value: "cover", label: "cover" },
];

export const backgroundrepeat = [
	{ value: "no-repeat", label: "no-repeat" },
	{ value: "repeat", label: "repeat" },
	{ value: "repeat-x", label: "repeat-x" },
	{ value: "repeat-y", label: "repeat-y" },
];
