/* eslint-disable import/prefer-default-export */
export const borderStyle = [
	{ value: "solid", label: "Solid" },
	{ value: "dotted", label: "Dotted" },
	{ value: "dashed", label: "Dashed" },
	{ value: "double", label: "Double" },
	{ value: "groove", label: "Groove" },
	{ value: "ridge", label: "Ridge" },
	{ value: "inset", label: "Inset" },
	{ value: "outset", label: "Outset" },
];
