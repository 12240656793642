export const RoutesPath = {
	// pages
	Presentation: { path: "/" },
	CssGenerator: { path: "/css-generator" },
	BackgroundColor: { path: "/css-generator/background-color-generator" },
	BackgroundImage: { path: "/css-generator/background-image-generator" },
	Border: { path: "/css-generator/border-generator" },
	BorderRadius: { path: "/css-generator/border-radius" },
	BoxShadow: { path: "/css-generator/box-shadow" },
	TextShadow: { path: "/css-generator/text-shadow" },
	Opacity: { path: "/css-generator/opacity" },
	BoxSizing: { path: "/css-generator/box-sizing" },
	Outline: { path: "/css-generator/outline" },
	Column: { path: "/css-generator/column" },
	Perspective: { path: "/css-generator/perspective" },
	Rotate: { path: "/css-generator/rotate" },
	Scale: { path: "/css-generator/scale" },
	Skew: { path: "/css-generator/skew" },
	Translate: { path: "/css-generator/translate" },
	Transition: { path: "/css-generator/transition" },
};

export default RoutesPath;
