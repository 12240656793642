/* eslint-disable import/prefer-default-export */
export const cssgeneratordata = [
	{
		title: "Background",
		children: [
			{
				title: "Background Color",
				link: "/css-generator/background-color-generator",
			},
			{
				title: "Background Image",
				link: "/css-generator/background-image-generator",
			},
		],
	},
	{
		title: "Box",
		children: [
			{
				title: "Border",
				link: "/css-generator/border-generator",
			},
			{
				title: "Border Radius",
				link: "/css-generator/border-radius",
			},
			{
				title: "Box Shadow",
				link: "/css-generator/box-shadow",
			},
			{
				title: "Text Shadow",
				link: "/css-generator/text-shadow",
			},
			{
				title: "Box Sizing",
				link: "/css-generator/box-sizing",
			},
			{
				title: "Opacity",
				link: "/css-generator/opacity",
			},
			{
				title: "Outline",
				link: "/css-generator/outline",
			},
		],
	},
	{
		title: "Layout",
		children: [
			{
				title: "Columns",
				link: "/css-generator/column",
			},
		],
	},
	{
		title: "Transform",
		children: [
			{
				title: "Perspective",
				link: "/css-generator/perspective",
			},
			{
				title: "Rotate",
				link: "/css-generator/rotate",
			},
			{
				title: "Scale",
				link: "/css-generator/scale",
			},
			{
				title: "Skew",
				link: "/css-generator/skew",
			},
			{
				title: "Translate",
				link: "/css-generator/translate",
			},
		],
	},
	{
		title: "Transition",
		children: [
			{
				title: "Transition",
				link: "/css-generator/transition",
			},
		],
	},
];
