/* eslint-disable eqeqeq */
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import InputNumber from "rc-input-number";

const SkewGenerator = ({ className, setCodeString }) => {
	const [skewx, setSkewx] = useState(5);
	const [skewy, setSkewy] = useState(5);

	setCodeString(
		`-webkit-transform: skew(${skewx}deg, ${skewy}deg);
-moz-transform: skew(${skewx}deg, ${skewy}deg);
-o-transform: skew(${skewx}deg, ${skewy}deg);
-ms-transform: skew(${skewx}deg, ${skewy}deg);
transform: skew(${skewx}deg, ${skewy}deg);`
	);

	return (
		<div className={`${className}`}>
			<div className="row">
				<div className="col-md-4">
					<div className="form-group">
						<Form.Label>Skew X </Form.Label>
						<InputNumber
							min={0}
							max={360}
							onChange={(e) => setSkewx(e)}
							value={skewx}
						/>
					</div>
				</div>
				<div className="col-md-4">
					<div className="form-group">
						<Form.Label>Skew Y </Form.Label>
						<InputNumber
							min={0}
							max={360}
							onChange={(e) => setSkewy(e)}
							value={skewy}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SkewGenerator;
