/* eslint-disable eqeqeq */
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";

const BoxSizingGenerator = ({ className, setCodeString }) => {
	const [sizing, setSizing] = useState("content-box");
	const sizingOption = [
		{ value: "content-box", label: "Content Box" },
		{ value: "border-box", label: "Border Box" },
		{ value: "inherit", label: "Inherit" },
	];

	setCodeString(`-webkit-box-sizing: ${sizing};
-moz-box-sizing: ${sizing};
box-sizing: ${sizing};`);

	return (
		<div className={`${className}`}>
			<div className="form-group">
				<Form.Label>Transform Style</Form.Label>
				<Select
					classNamePrefix="select"
					options={sizingOption}
					defaultValue={sizingOption[0]}
					menuPosition="fixed"
					isSearchable={false}
					onChange={(e) => setSizing(e.value)}
				/>
			</div>
		</div>
	);
};

export default BoxSizingGenerator;
