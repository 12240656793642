import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { IconBrandCss3, IconMenu, IconX } from "@tabler/icons";
import { RoutesPath } from "../../routesPath";
import { ReactComponent as Logo } from "../../assets/img/logo.svg";

const Header = () => {
	const [sticky, setSticky] = useState(false);
	const [menushow, setMenushow] = useState(false);
	const navSticky = () => {
		if (window.scrollY > 0) {
			setSticky(true);
		} else {
			setSticky(false);
		}
	};
	window.addEventListener("scroll", navSticky);
	return (
		<div className={`cm-header-wrap ${sticky ? "shrink" : ""} `}>
			<div className="cm-header">
				<div className="cm-logo">
					<Link to="/">
						<Logo />
					</Link>
				</div>
				{/* sidebar toggle button start */}
				<button
					type="button"
					className="sidebar-toggle d-md-none"
					onClick={() => setMenushow(!menushow)}>
					<IconMenu />
				</button>
				{/* sidebar toggle button end */}
				<div className={`cm-menu-wrap ${menushow ? "menu-show" : ""}`}>
					<button
						type="button"
						className="sidebar-toggle d-md-none"
						onClick={() => setMenushow(false)}>
						<IconX />
					</button>
					<ul className="cm-menu">
						<li className="cm-menu__item">
							<NavLink
								to={RoutesPath.CssGenerator.path}
								className="cm-menu__link cm-menu__link--primary"
								onClick={() => setMenushow(false)}>
								<span className="cm-menu__icon">
									<IconBrandCss3 />
								</span>
								<span className="cm-menu__txt">CSS Generator</span>
							</NavLink>
						</li>
						{/* <li className="cm-menu__item">
							<Link
								to="/"
								className="cm-menu__link cm-menu__link--primary"
								onClick={() => setMenushow(false)}>
								<span className="cm-menu__icon">
									<IconLayoutCards />
								</span>
								<span className="cm-menu__txt">Flexbox Generator</span>
							</Link>
						</li>
						<li className="cm-menu__item">
							<Link
								to="/"
								className="cm-menu__link cm-menu__link--secondary"
								onClick={() => setMenushow(false)}>
								<span className="cm-menu__icon">
									<IconDimensions />
								</span>
								<span className="cm-menu__txt">Button Generator</span>
							</Link>
						</li>
						<li className="cm-menu__item">
							<Link
								to="/"
								className="cm-menu__link cm-menu__link--danger"
								onClick={() => setMenushow(false)}>
								<span className="cm-menu__icon">
									<IconForms />
								</span>
								<span className="cm-menu__txt">Input Generator</span>
							</Link>
						</li>
						<li className="cm-menu__item">
							<Link
								to="/"
								className="cm-menu__link cm-menu__link--dark"
								onClick={() => setMenushow(false)}>
								<span className="cm-menu__icon">
									<IconTypography />
								</span>
								<span className="cm-menu__txt">Typography Generator</span>
							</Link>
						</li>
						<li className="cm-menu__item">
							<Link
								to="/"
								className="cm-menu__link cm-menu__link--info"
								onClick={() => setMenushow(false)}>
								<span className="cm-menu__icon">
									<IconBlur />
								</span>
								<span className="cm-menu__txt">Glass UI Generator</span>
							</Link>
						</li> */}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default Header;
