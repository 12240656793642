/* eslint-disable eqeqeq */
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Slider from "react-rangeslider";

const RotateGenerator = ({ className, setCodeString }) => {
	const [rotate, setRotate] = useState(0);
	const [rotatex, setRotatex] = useState(0);
	const [rotatey, setRotatey] = useState(0);
	const [rotatez, setRotatez] = useState(0);
	const [checked, setChecked] = useState(false);
	const horizontalLabels = {
		0: "Low",
		360: "High",
	};

	setCodeString(
		`${
			checked
				? `-webkit-transform: rotateX(${rotatex}deg) rotateY(${rotatey}deg) rotateZ(${rotatez}deg);
-moz-transform: rotateX(${rotatex}deg) rotateY(${rotatey}deg) rotateZ(${rotatez}deg);
-o-transform: rotateX(${rotatex}deg) rotateY(${rotatey}deg) rotateZ(${rotatez}deg);
-ms-transform: rotateX(${rotatex}deg) rotateY(${rotatey}deg) rotateZ(${rotatez}deg);
transform: rotateX(${rotatex}deg) rotateY(${rotatey}deg) rotateZ(${rotatez}deg);`
				: `-webkit-transform: rotate(${`${rotate}deg`});
-moz-transform: rotate(${`${rotate}deg`});
-o-transform: rotate(${`${rotate}deg`});
-ms-transform: rotate(${`${rotate}deg`});
transform: rotate(${`${rotate}deg`});`
		}`
	);

	return (
		<div className={`${className}`}>
			<div className="checkbox-2d3d">
				<input
					type="checkbox"
					id="checkbox2d"
					checked={checked}
					onChange={(e) => setChecked(e.target.checked)}
				/>
				<label htmlFor="checkbox2d">
					<span className="d2">2D</span>
					<span className="d3">3D</span>
				</label>
			</div>
			{checked ? (
				<>
					<div className="row">
						<div className="col-md-6">
							<div className="form-group">
								<Form.Label>rotateX </Form.Label>
								<Slider
									min={0}
									max={360}
									value={rotatex}
									labels={horizontalLabels}
									onChange={(e) => setRotatex(e)}
								/>
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<Form.Label>rotateY </Form.Label>
								<Slider
									min={0}
									max={360}
									value={rotatey}
									labels={horizontalLabels}
									onChange={(e) => setRotatey(e)}
								/>
							</div>
						</div>
						<div className="col-md-12">
							<div className="form-group">
								<Form.Label>rotateZ </Form.Label>
								<Slider
									min={0}
									max={360}
									value={rotatez}
									labels={horizontalLabels}
									onChange={(e) => setRotatez(e)}
								/>
							</div>
						</div>
					</div>
				</>
			) : (
				<div className="form-group">
					<Form.Label>Rotate (Degrees) </Form.Label>
					<Slider
						min={0}
						max={360}
						value={rotate}
						labels={horizontalLabels}
						onChange={(e) => setRotate(e)}
					/>
				</div>
			)}
		</div>
	);
};

export default RotateGenerator;
