import React from "react";
import { NavLink } from "react-router-dom";
import { cssgeneratordata } from "../../Data/cssgeneratordata";
import "./_css-generator.scss";

// menu item start
export const MenuItems = ({ items, index }) => (
	<li key={index}>
		{items.children && items.children.length > 0 ? (
			<>
				<div className="sidebar-title-item">{items.title}</div>
				<SubMenuList submenus={items.children} />
			</>
		) : (
			<>
				{items.title ? (
					<div className="sidebar-title-item">{items.title}</div>
				) : (
					""
				)}
			</>
		)}
	</li>
);
// menu item end
// sub menu item start
export const SubMenuList = ({ submenus }) => (
	<ul className="sidebar-list">
		{submenus.map((submenu, index) => (
			<li key={index}>
				{submenu.link ? (
					<NavLink to={submenu.link}>
						{submenu.title ? (
							<>
								<span className="txt">{submenu.title}</span>
							</>
						) : (
							""
						)}
					</NavLink>
				) : (
					""
				)}
			</li>
		))}
	</ul>
);
// sub menu item end

const GeneratorSidebar = () => (
	<>
		<div className="generator-sidebar">
			<ul>
				{cssgeneratordata.map((menu, index) => (
					<MenuItems items={menu} key={index} />
				))}
			</ul>
		</div>
	</>
);

export default GeneratorSidebar;
