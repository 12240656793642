import React, { useState } from "react";
import {
	IconBorderOuter,
	IconBorderLeft,
	IconBorderTop,
	IconBorderRight,
	IconBorderBottom,
} from "@tabler/icons";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import InputNumber from "rc-input-number";
import ColorPicker from "./ColorPicker";
import { borderStyle } from "../../Data/borderdata";

export const RadioButton = ({
	changed,
	id,
	isSelected,
	label,
	value,
	icon,
}) => (
	<div className="radiobutton-dd">
		<input
			id={id}
			onChange={changed}
			value={value}
			type="checkbox"
			checked={isSelected}
		/>
		<label htmlFor={id}>
			{icon}
			{label}
		</label>
	</div>
);

export const BorderComponent = ({ borderGen, setBorderGen }) => (
	<div className="row">
		<div className="col-md-3">
			<div className="form-group">
				<Form.Label>Size</Form.Label>
				<InputNumber
					min={1}
					onChange={(e) =>
						setBorderGen((prevState) => ({
							...prevState,
							size: e,
						}))
					}
					value={borderGen.size}
				/>
				{/* <Form.Control
					type="text"
					value={borderGen.size}
					className="form-control-sm"
					onChange={(e) =>
						setBorderGen((prevState) => ({
							...prevState,
							size: e.target.value,
						}))
					}
				/> */}
			</div>
		</div>
		<div className="col-md-4">
			<div className="form-group">
				<Form.Label>Style</Form.Label>
				<Select
					classNamePrefix="select"
					options={borderStyle}
					defaultValue={borderGen.style}
					menuPosition="fixed"
					menuShouldBlockScroll={true}
					onChange={(e) =>
						setBorderGen((prevState) => ({ ...prevState, style: e.value }))
					}
				/>
			</div>
		</div>
		<div className="col-md-5">
			<ColorPicker
				label="Color"
				data={borderGen.color}
				setData={(e) => {
					setBorderGen((prevState) => ({
						...prevState,
						color: e,
					}));
				}}
			/>
		</div>
	</div>
);

const BorderGenerator = ({ className, setCodeString }) => {
	const [checkall, setCheckall] = useState(true);
	const [checkbl, setCheckbl] = useState(false);
	const [checkbt, setCheckbt] = useState(false);
	const [checkbr, setCheckbr] = useState(false);
	const [checkbb, setCheckbb] = useState(false);

	const [borderGen, setBorderGen] = useState({
		size: "1",
		style: "solid",
		color: "#ff7700",
	});
	const [borderGenl, setBorderGenl] = useState({
		size: "1",
		style: "solid",
		color: "#ff7700",
	});
	const [borderGent, setBorderGent] = useState({
		size: "1",
		style: "solid",
		color: "#ff7700",
	});
	const [borderGenr, setBorderGenr] = useState({
		size: "1",
		style: "solid",
		color: "#ff7700",
	});
	const [borderGenb, setBorderGenb] = useState({
		size: "1",
		style: "solid",
		color: "#ff7700",
	});

	setCodeString(
		`border: ${borderGen.size}px ${borderGen.style} ${borderGen.color};
${
	checkbl
		? `border-left: ${borderGenl.size}px ${borderGenl.style} ${borderGenl.color};`
		: ""
}
${
	checkbt
		? `border-top: ${borderGent.size}px ${borderGent.style} ${borderGent.color};`
		: ""
}
${
	checkbr
		? `border-right: ${borderGenr.size}px ${borderGenr.style} ${borderGenr.color};`
		: ""
}
${
	checkbb
		? `border-bottom: ${borderGenb.size}px ${borderGenb.style} ${borderGenb.color};`
		: ""
}`
	);

	return (
		<div className={`${className}`}>
			<div className="d-flex justify-content-between align-items-center pb-2">
				<div className="text-normal fw-medium text-dark">Border</div>
				<Dropdown>
					<Dropdown.Toggle
						variant="link"
						className="btn-no-arrow text-decoration-none p-0">
						<IconBorderOuter size={32} strokeLinejoin="miter" />
					</Dropdown.Toggle>

					<Dropdown.Menu align="end" className="p-2">
						<RadioButton
							changed={(e) => setCheckall(e.target.checked)}
							id="1"
							isSelected={checkall}
							label="Same on all sides"
							value="All"
							icon={<IconBorderOuter />}
						/>
						<RadioButton
							changed={(e) => setCheckbl(e.target.checked)}
							id="2"
							isSelected={checkbl}
							label="Border Left"
							value="border left"
							icon={<IconBorderLeft />}
						/>
						<RadioButton
							changed={(e) => setCheckbt(e.target.checked)}
							id="3"
							isSelected={checkbt}
							label="Border Top"
							value="border top"
							icon={<IconBorderTop />}
						/>
						<RadioButton
							changed={(e) => setCheckbr(e.target.checked)}
							id="4"
							isSelected={checkbr}
							label="Border Right"
							value="border right"
							icon={<IconBorderRight />}
						/>
						<RadioButton
							changed={(e) => setCheckbb(e.target.checked)}
							id="5"
							isSelected={checkbb}
							label="Border Bottom"
							value="border bottom"
							icon={<IconBorderBottom />}
						/>
					</Dropdown.Menu>
				</Dropdown>
			</div>

			{checkall && (
				<>
					<BorderComponent borderGen={borderGen} setBorderGen={setBorderGen} />
				</>
			)}
			{checkbl && (
				<>
					<hr className="mt-0 text-dark" />
					<div className="text-normal fw-medium text-dark pb-2">
						Border Left
					</div>
					<BorderComponent
						borderGen={borderGenl}
						setBorderGen={setBorderGenl}
					/>
				</>
			)}
			{checkbt && (
				<>
					<hr className="mt-0 text-dark" />
					<div className="text-normal fw-medium text-dark pb-2">Border Top</div>
					<BorderComponent
						borderGen={borderGent}
						setBorderGen={setBorderGent}
					/>
				</>
			)}
			{checkbr && (
				<>
					<hr className="mt-0 text-dark" />
					<div className="text-normal fw-medium text-dark pb-2">
						Border Right
					</div>
					<BorderComponent
						borderGen={borderGenr}
						setBorderGen={setBorderGenr}
					/>
				</>
			)}
			{checkbb && (
				<>
					<hr className="mt-0 text-dark" />
					<div className="text-normal fw-medium text-dark pb-2">
						Border Bottom
					</div>
					<BorderComponent
						borderGen={borderGenb}
						setBorderGen={setBorderGenb}
					/>
				</>
			)}
		</div>
	);
};

export default BorderGenerator;
