import React, { useState } from "react";
// import PropTypes from "prop-types";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { IconCopy, IconChecks } from "@tabler/icons";
import { Toast, ToastContainer } from "react-bootstrap";
import coy from "react-syntax-highlighter/dist/esm/styles/prism/coy";

const CodePreview = ({ data }) => {
	const [showToast, setShowToast] = useState(false);
	return (
		<>
			<div className="d-flex justify-content-between align-items-end pb-2">
				<h6 className="h6">Code</h6>
				<CopyToClipboard
					text={data}
					className="copycode-btn"
					onCopy={() => setShowToast(!showToast)}>
					<button type="button">
						<IconCopy />
					</button>
				</CopyToClipboard>
			</div>
			<div className="option-box card card--custom">
				<div className="card-body">
					<div className="code-preview">
						<SyntaxHighlighter
							language="css"
							wrapLongLines="true"
							style={coy}
							className="code-preview-box">
							{data}
						</SyntaxHighlighter>

						<ToastContainer
							className="clipboard-toast-container"
							containerPosition="fixed">
							<Toast
								show={showToast}
								autohide
								className="clipboard-toast"
								onClose={() => setShowToast(!showToast)}>
								<Toast.Header>
									Copied to clipboard <IconChecks />
								</Toast.Header>
							</Toast>
						</ToastContainer>
					</div>
				</div>
			</div>
		</>
	);
};

// CodePreview.propTypes = {
// 	data: PropTypes.string.isRequired,
// };

export default CodePreview;
