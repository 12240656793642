import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import App from "./pages/App";

import ScrollToTop from "./components/ScrollToTop";

import "./scss/cm_style.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<BrowserRouter>
		<ScrollToTop />
		<Header />
		<App />
		<Footer />
	</BrowserRouter>
);
