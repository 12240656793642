import React, { useState } from "react";
import PropTypes from "prop-types";
import { SketchPicker } from "react-color";
import { Dropdown, Form } from "react-bootstrap";

const ColorPicker = ({ data, setData, label, className }) => {
	const [rgba, setRgba] = useState(false);
	return (
		<>
			<div className={`form-group ${className}`}>
				<div className="d-flex justify-content-between align-items-center mb-1">
					<label htmlFor="Options" className="form-label mb-0">
						{label}
					</label>
					<Form.Check
						type="switch"
						label="RGBA"
						id="rgba-switch"
						checked={rgba}
						className="mb-0"
						onChange={(e) => setRgba(e.target.checked)}
					/>
				</div>
				<Dropdown>
					<Dropdown.Toggle
						variant="color"
						size="sm"
						className="btn-no-arrow btn-block">
						<span style={{ backgroundColor: `${data}` }} />
					</Dropdown.Toggle>

					<Dropdown.Menu className="p-0">
						{rgba ? (
							<SketchPicker
								color={data}
								onChange={(color) => {
									setData(
										`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
									);
									// console.log(color.rgb);
								}}
							/>
						) : (
							<SketchPicker
								color={data}
								disableAlpha
								onChange={(color) => {
									setData(color.hex);
									// console.log(color.rgb);
								}}
							/>
						)}
					</Dropdown.Menu>
				</Dropdown>
			</div>
		</>
	);
};

ColorPicker.propTypes = {
	data: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	setData: PropTypes.func.isRequired,
};

export default ColorPicker;
