/* eslint-disable import/prefer-default-export */
const commonOption = [
	{ value: "opacity", label: "Opacity" },
	{ value: "margin", label: "Margin" },
	{ value: "padding", label: "Padding" },
	{ value: "border", label: "Border" },
	{ value: "transform", label: "Transform" },
	{ value: "filter", label: "Filter" },
	{ value: "flex", label: "Flex" },
];

const backgroundOption = [
	{ value: "background-color", label: "Background Color" },
	{ value: "background-position", label: "Background Position" },
	{ value: "text-shadow", label: "Text Shadow" },
	{ value: "box-shadow", label: "Box Shadow" },
];

const sizeOption = [
	{ value: "width", label: "Width" },
	{ value: "height", label: "Height" },
	{ value: "max-height", label: "Max Height" },
	{ value: "max-width", label: "Max Width" },
	{ value: "min-height", label: "Min Height" },
	{ value: "min-width", label: "Min Width" },
];

const bordersOption = [
	{ value: "border-radius", label: "Border Radius" },
	{ value: "border-color", label: "Border Color" },
	{ value: "border-width", label: "Border Width" },
];

const typographyOption = [
	{ value: "font-color", label: "Font Color" },
	{ value: "font-size", label: "Font Size" },
	{ value: "line-height", label: "Line Height" },
	{ value: "letter-spacing", label: "Letter Spacing" },
	{ value: "text-indent", label: "Text Indent" },
	{ value: "word-spacing", label: "Word Spacing" },
	{ value: "word-spacing", label: "Word Spacing" },
	{ value: "font-variations", label: "Font Variations" },
];

const positionOption = [
	{ value: "top", label: "Top" },
	{ value: "left", label: "Left" },
	{ value: "bottom", label: "Bottom" },
	{ value: "right", label: "Right" },
	{ value: "z-index", label: "z-index" },
];

const marginOption = [
	{ value: "margin-bottom", label: "Margin Bottom" },
	{ value: "margin-left", label: "Margin Left" },
	{ value: "margin-right", label: "Margin Right" },
	{ value: "margin-top", label: "Margin Top" },
];

const paddingOption = [
	{ value: "padding-bottom", label: "Padding Bottom" },
	{ value: "padding-left", label: "Padding Left" },
	{ value: "padding-right", label: "Padding Right" },
	{ value: "padding-top", label: "Padding Top" },
];

const flexOption = [
	{ value: "flex-grow", label: "Flex Grow" },
	{ value: "flex-shrink", label: "Flex Shrink" },
	{ value: "flex-basis", label: "Flex Basis" },
];

const advancedOption = [{ value: "all", label: "All Properties" }];

export const transitionproperty = [
	{
		label: "Advanced",
		options: advancedOption,
	},
	{
		label: "Common",
		options: commonOption,
	},
	{
		label: "Background",
		options: backgroundOption,
	},
	{
		label: "Size",
		options: sizeOption,
	},
	{
		label: "Borders",
		options: bordersOption,
	},
	{
		label: "Typography",
		options: typographyOption,
	},
	{
		label: "Position",
		options: positionOption,
	},
	{
		label: "Margin",
		options: marginOption,
	},
	{
		label: "Padding",
		options: paddingOption,
	},
	{
		label: "Flex",
		options: flexOption,
	},
];
