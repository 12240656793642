import React, { useState } from "react";
import GeneratorTitleBlock from "../GeneratorTitleBlock/GeneratorTitleBlock";
import CodePreview from "../../../../components/CodePreview/CodePreview";
import BorderRadiusGenerator from "../../../../components/FormComponent/BorderRadiusGenerator";

const BorderRadius = () => {
	const [codeString, setCodeString] = useState("");
	return (
		<>
			<GeneratorTitleBlock title="Border Radius" />

			<div className="generator-area">
				<div className="generator-area-options half-width">
					<h6 className="h6 pb-2">Options</h6>
					<div className="option-box card card--custom mb-4">
						<div className="card-body form-wrap">
							<BorderRadiusGenerator
								codeString={codeString}
								setCodeString={setCodeString}
							/>
						</div>
					</div>
					<CodePreview data={codeString} />
				</div>
				<div className="generator-area-preview half-width">
					<h6 className="h6 pb-2">Preview</h6>
					<div className="option-box card card--custom mb-4 d-inline-flex">
						<div className="card-body">
							<style
								// eslint-disable-next-line react/no-danger
								dangerouslySetInnerHTML={{
									__html: `.bor { ${codeString} }`,
								}}
							/>
							<div className="color-preview-box w-200 h-200 bg-secondary bor transition" />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default BorderRadius;
