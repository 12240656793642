import React from "react";

const Footer = () => (
	<>
		<div className="footer-wrap">
			<footer>
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							Copyright {new Date().getFullYear()} CSS Magic. All Rights
							Reserved.
						</div>
						<div className="col-md-6 text-md-end pt-2 pt-md-0">
							Design & Develop by{" "}
							<a
								href="https://github.com/dropways"
								target="_blank"
								rel="noreferrer">
								Ankit Hingarajiya
							</a>
						</div>
					</div>
				</div>
			</footer>
		</div>
	</>
);

export default Footer;
