import React, { useState } from "react";
import GeneratorTitleBlock from "../GeneratorTitleBlock/GeneratorTitleBlock";
import CodePreview from "../../../../components/CodePreview/CodePreview";
import TextShadowGenerator from "../../../../components/FormComponent/TextShadowGenerator";

const TextShadow = () => {
	const [codeString, setCodeString] = useState("");
	return (
		<>
			<GeneratorTitleBlock title="Text Shadow" />

			<div className="generator-area">
				<div className="generator-area-options half-width">
					<h6 className="h6 pb-2">Options</h6>
					<div className="option-box card card--custom mb-4">
						<div className="card-body form-wrap">
							<TextShadowGenerator
								codeString={codeString}
								setCodeString={setCodeString}
							/>
						</div>
					</div>
					<CodePreview data={codeString} />
				</div>
				<div className="generator-area-preview half-width">
					<h6 className="h6 pb-2">Preview</h6>
					<div className="option-box card card--custom mb-4">
						<div className="card-body">
							<style
								// eslint-disable-next-line react/no-danger
								dangerouslySetInnerHTML={{
									__html: `.shadow-p { ${codeString} }`,
								}}
							/>
							<h2 className="h1 py-4 shadow-p text-center">
								CSS <br />
								Magics
							</h2>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default TextShadow;
