import React from "react";
import Banner from "./Banner/Banner";
import FeatureSection from "./FeatureSection/FeatureSection";

const Home = () => (
	<>
		<Banner />
		<FeatureSection />
	</>
);

export default Home;
