import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
	IconArrowNarrowRight,
	IconBrandCss3,
	IconTypography,
	IconLayoutCards,
	IconDimensions,
	IconForms,
	IconBlur,
} from "@tabler/icons";
import { RoutesPath } from "../../../routesPath";
import cssimg from "../../../assets/img/css-generator-img.png";
import flexboximg from "../../../assets/img/flexbox-generator-img.png";
import buttonimg from "../../../assets/img/button-generator-img.png";
import typographyimg from "../../../assets/img/typography-generator-img.png";
import inputimg from "../../../assets/img/input-generator-img.png";
import glassimg from "../../../assets/img/glass-ui-generator-img.png";
import "./_feature-section.scss";

const FeatureSection = () => (
	<>
		<div className="feature-section-wrap">
			<div className="container">
				<ul className="feature-list row">
					<li className="feature-list__item col-md-12">
						<div className="feature-box feature-box--primary">
							<div className="feature-box__content">
								<div className="feature-box__icon">
									<div className="icon-box">
										<IconBrandCss3 size={64} stroke={1.5} />
									</div>
								</div>
								<div className="feature-box__content-text">
									<h2 className="feature-box__title h4">CSS Generator</h2>
									<div className="feature-box__txt">
										Etiam egestas mollis interdum. Nullam imperdiet, sapien sed
										consectetur, diam arcu imperdiet massa, at aliquam ligula ex
										in mauris.
									</div>
									<div className="feature-box__cta">
										<Link
											to={RoutesPath.BackgroundColor.path}
											className="btn-txt-icon btn-block btn-icon-right btn btn-primary">
											let&apos;s play it <IconArrowNarrowRight />
										</Link>
									</div>
								</div>
							</div>
							<div className="feature-box__img">
								<img src={cssimg} alt="CSS Generator" />
							</div>
						</div>
					</li>
					<li className="feature-list__item col-md-12 col-lg-6">
						<div className="feature-box feature-box--vertical feature-box--success">
							<div className="feature-box__content">
								<div className="feature-box__icon">
									<div className="icon-box">
										<IconLayoutCards size={64} stroke={1.5} />
									</div>
								</div>
								<div className="feature-box__content-text">
									<h2 className="feature-box__title h4">Flexbox Generator</h2>
									<div className="feature-box__txt">
										Etiam egestas mollis interdum. Nullam imperdiet, sapien sed
										consectetur, diam arcu imperdiet massa, at aliquam ligula ex
										in mauris.
									</div>
									<div className="feature-box__cta">
										<Button
											variant="success"
											className="btn-txt-icon btn-block">
											coming soon
										</Button>
									</div>
								</div>
							</div>
							<div className="feature-box__img">
								<img src={flexboximg} alt="Flexbox Generator" />
							</div>
						</div>
					</li>
					<li className="feature-list__item col-md-12 col-lg-6">
						<div className="feature-box feature-box--vertical feature-box--secondary">
							<div className="feature-box__content">
								<div className="feature-box__icon">
									<div className="icon-box">
										<IconDimensions size={64} stroke={1.5} />
									</div>
								</div>
								<div className="feature-box__content-text">
									<h2 className="feature-box__title h4">Button Generator</h2>
									<div className="feature-box__txt">
										Etiam egestas mollis interdum. Nullam imperdiet, sapien sed
										consectetur, diam arcu imperdiet massa, at aliquam ligula ex
										in mauris.
									</div>
									<div className="feature-box__cta">
										<Button
											variant="secondary"
											className="btn-txt-icon btn-block">
											coming soon
										</Button>
									</div>
								</div>
							</div>
							<div className="feature-box__img">
								<img src={buttonimg} alt="Button Generator" />
							</div>
						</div>
					</li>
					<li className="feature-list__item col-md-12">
						<div className="feature-box feature-box--dark">
							<div className="feature-box__content">
								<div className="feature-box__icon">
									<div className="icon-box">
										<IconTypography size={64} stroke={1.5} />
									</div>
								</div>
								<div className="feature-box__content-text">
									<h2 className="feature-box__title h4">
										Typography Generator
									</h2>
									<div className="feature-box__txt">
										Etiam egestas mollis interdum. Nullam imperdiet, sapien sed
										consectetur, diam arcu imperdiet massa, at aliquam ligula ex
										in mauris.
									</div>
									<div className="feature-box__cta">
										<Button variant="dark" className="btn-txt-icon btn-block">
											coming soon
										</Button>
									</div>
								</div>
							</div>
							<div className="feature-box__img">
								<img src={typographyimg} alt="Typography Generator" />
							</div>
						</div>
					</li>
					<li className="feature-list__item col-md-12 col-lg-6">
						<div className="feature-box feature-box--vertical reverse-column feature-box--danger">
							<div className="feature-box__content">
								<div className="feature-box__icon">
									<div className="icon-box">
										<IconForms size={64} stroke={1.5} />
									</div>
								</div>
								<div className="feature-box__content-text">
									<h2 className="feature-box__title h4">Input Generator</h2>
									<div className="feature-box__txt">
										Etiam egestas mollis interdum. Nullam imperdiet, sapien sed
										consectetur, diam arcu imperdiet massa, at aliquam ligula ex
										in mauris.
									</div>
									<div className="feature-box__cta">
										<Button variant="danger" className="btn-txt-icon btn-block">
											coming soon
										</Button>
									</div>
								</div>
							</div>
							<div className="feature-box__img">
								<img src={inputimg} alt="Input Generator" />
							</div>
						</div>
					</li>
					<li className="feature-list__item col-md-12 col-lg-6">
						<div className="feature-box feature-box--vertical reverse-column feature-box--info">
							<div className="feature-box__content">
								<div className="feature-box__icon">
									<div className="icon-box">
										<IconBlur size={64} stroke={1.5} />
									</div>
								</div>
								<div className="feature-box__content-text">
									<h2 className="feature-box__title h4">Glass UI Generator</h2>
									<div className="feature-box__txt">
										Etiam egestas mollis interdum. Nullam imperdiet, sapien sed
										consectetur, diam arcu imperdiet massa, at aliquam ligula ex
										in mauris.
									</div>
									<div className="feature-box__cta">
										<Button variant="info" className="btn-txt-icon btn-block">
											coming soon
										</Button>
									</div>
								</div>
							</div>
							<div className="feature-box__img">
								<img src={glassimg} alt="Glass UI Generator" />
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</>
);

export default FeatureSection;
