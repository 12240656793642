/* eslint-disable eqeqeq */
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import InputNumber from "rc-input-number";
import ColorPicker from "./ColorPicker";

const TextShadowGenerator = ({ className, setCodeString }) => {
	const [shadowX, setShadowX] = useState(0);
	const [shadowY, setShadowY] = useState(0);
	const [shadowBlur, setShadowBlur] = useState(30);
	const [Color, setColor] = useState("#ff7700");

	setCodeString(
		`text-shadow: ${shadowX}px ${shadowY}px ${shadowBlur}px ${Color};`
	);

	return (
		<div className={`${className}`}>
			<div className="row">
				<div className="col-6 col-md-6 col-lg-2">
					<div className="form-group">
						<Form.Label>X</Form.Label>
						<InputNumber onChange={(e) => setShadowX(e)} value={shadowX} />
					</div>
				</div>
				<div className="col-6 col-md-6 col-lg-2">
					<div className="form-group">
						<Form.Label>Y</Form.Label>
						<InputNumber onChange={(e) => setShadowY(e)} value={shadowY} />
					</div>
				</div>
				<div className="col-6 col-md-6 col-lg-3">
					<div className="form-group">
						<Form.Label>Blur</Form.Label>
						<InputNumber
							onChange={(e) => setShadowBlur(e)}
							value={shadowBlur}
						/>
					</div>
				</div>
				<div className="col-6 col-md-6 col-lg-5">
					<ColorPicker
						label="Color"
						data={Color}
						setData={(e) => {
							setColor(e);
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default TextShadowGenerator;
