import React, { useState } from "react";
import GeneratorTitleBlock from "../GeneratorTitleBlock/GeneratorTitleBlock";
import CodePreview from "../../../../components/CodePreview/CodePreview";
import OpacityGenerator from "../../../../components/FormComponent/OpacityGenerator";

const Opacity = () => {
	const [codeString, setCodeString] = useState("");
	return (
		<>
			<GeneratorTitleBlock title="Opacity" />

			<div className="generator-area">
				<div className="generator-area-options half-width">
					<h6 className="h6 pb-2">Options</h6>
					<div className="option-box card card--custom mb-4">
						<div className="card-body form-wrap">
							<OpacityGenerator
								codeString={codeString}
								setCodeString={setCodeString}
							/>
						</div>
					</div>
					<CodePreview data={codeString} />
				</div>
				<div className="generator-area-preview half-width">
					<h6 className="h6 pb-2">Preview</h6>
					<div className="option-box card card--custom mb-4">
						<div className="card-body">
							<style
								// eslint-disable-next-line react/no-danger
								dangerouslySetInnerHTML={{
									__html: `.opacity-box { ${codeString} }`,
								}}
							/>
							<div className="color-preview-box h-200 bg-primary rounded opacity-box transition" />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default Opacity;
