import React from "react";
import { ReactComponent as ChromeIcon } from "../../assets/img/icons/chrome.svg";
import { ReactComponent as FirefoxIcon } from "../../assets/img/icons/firefox.svg";
import { ReactComponent as SafariIcon } from "../../assets/img/icons/safari.svg";
import { ReactComponent as EdgeIcon } from "../../assets/img/icons/edge.svg";
import { ReactComponent as OperaIcon } from "../../assets/img/icons/opera.svg";
import "./_browser-list.scss";

const Browsers = () => (
	<div className="browser-list">
		<ul>
			<li className="text-primary">
				<ChromeIcon />
			</li>
			<li className="text-secondary">
				<FirefoxIcon />
			</li>
			<li className="text-success">
				<SafariIcon />
			</li>
			<li className="text-dark">
				<EdgeIcon />
			</li>
			<li className="text-info">
				<OperaIcon />
			</li>
		</ul>
	</div>
);

export default Browsers;
