/* eslint-disable eqeqeq */
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import InputNumber from "rc-input-number";

const ScaleGenerator = ({ className, setCodeString }) => {
	const [scale, setScale] = useState(1);
	const [rotatex, setRotatex] = useState(1);
	const [rotatey, setRotatey] = useState(1);
	const [rotatez, setRotatez] = useState(1);
	const [checked, setChecked] = useState(false);

	setCodeString(
		`${
			checked
				? `-webkit-transform: scale3d(${rotatex}, ${rotatey}, ${rotatez});
-moz-transform: scale3d(${rotatex}, ${rotatey}, ${rotatez});
-o-transform: scale3d(${rotatex}, ${rotatey}, ${rotatez});
-ms-transform: scale3d(${rotatex}, ${rotatey}, ${rotatez});
transform: scale3d(${rotatex}, ${rotatey}, ${rotatez});`
				: `-webkit-transform: scale(${`${scale}`});
-moz-transform: scale(${`${scale}`});
-o-transform: scale(${`${scale}`});
-ms-transform: scale(${`${scale}`});
transform: scale(${`${scale}`});`
		}`
	);

	return (
		<div className={`${className}`}>
			<div className="checkbox-2d3d">
				<input
					type="checkbox"
					id="checkbox2d"
					checked={checked}
					onChange={(e) => setChecked(e.target.checked)}
				/>
				<label htmlFor="checkbox2d">
					<span className="d2">2D</span>
					<span className="d3">3D</span>
				</label>
			</div>
			{checked ? (
				<>
					<div className="row">
						<div className="col-md-4">
							<div className="form-group">
								<Form.Label>Scale X </Form.Label>
								<InputNumber
									min={0}
									max={2}
									step={0.1}
									onChange={(e) => setRotatex(e)}
									value={rotatex}
								/>
							</div>
						</div>
						<div className="col-md-4">
							<div className="form-group">
								<Form.Label>Scale Y </Form.Label>
								<InputNumber
									min={0}
									max={2}
									step={0.1}
									onChange={(e) => setRotatey(e)}
									value={rotatey}
								/>
							</div>
						</div>
						<div className="col-md-4">
							<div className="form-group">
								<Form.Label>Scale Z </Form.Label>
								<InputNumber
									min={0}
									max={2}
									step={0.1}
									onChange={(e) => setRotatez(e)}
									value={rotatez}
								/>
							</div>
						</div>
					</div>
				</>
			) : (
				<div className="row">
					<div className="col-md-4">
						<div className="form-group">
							<Form.Label>Scale</Form.Label>
							<InputNumber
								min={0}
								max={2}
								step={0.1}
								onChange={(e) => setScale(e)}
								value={scale}
							/>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default ScaleGenerator;
