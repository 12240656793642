import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import GeneratorTitleBlock from "../GeneratorTitleBlock/GeneratorTitleBlock";
import CodePreview from "../../../../components/CodePreview/CodePreview";
import ColorPicker from "../../../../components/FormComponent/ColorPicker";
import SelectPicker from "../../../../components/FormComponent/SelectPicker";
import {
	backgroundposition,
	backgroundsize,
	backgroundrepeat,
} from "../../../../Data/backgroundimage";

const BackgroundImage = () => {
	const [Color, setColor] = useState("#ff7700");
	const [bgPosition, setBgPosition] = useState("center center");
	const [bgSize, setBgSize] = useState("auto");
	const [bgRepeat, setBgRepeat] = useState("no-repeat");
	const [url, setUrl] = useState("https://picsum.photos/id/289/1000/1000");
	const codeString = `background: ${Color} url(${url}) ${bgPosition}/${bgSize} ${bgRepeat};`;
	return (
		<>
			<GeneratorTitleBlock title="Background Image Generator" />

			<div className="generator-area">
				<div className="generator-area-options">
					<h6 className="h6 pb-2">Options</h6>
					<div className="option-box card card--custom">
						<div className="card-body form-wrap">
							<ColorPicker
								data={Color}
								setData={setColor}
								label="Background color"
							/>
							<Form.Group className="form-group">
								<Form.Label>Image URL</Form.Label>
								<Form.Control
									type="text"
									value={url}
									className="form-control-sm"
									onChange={(e) => setUrl(e.target.value)}
								/>
							</Form.Group>
							<SelectPicker
								label="Position"
								option={backgroundposition}
								data={bgPosition}
								setdata={setBgPosition}
							/>
							<SelectPicker
								label="Size"
								option={backgroundsize}
								data={bgSize}
								setdata={setBgSize}
							/>
							<SelectPicker
								label="Repeat"
								option={backgroundrepeat}
								data={bgRepeat}
								setdata={setBgRepeat}
							/>
						</div>
					</div>
				</div>
				<div className="generator-area-preview">
					<h6 className="h6 pb-2">Preview</h6>
					<div className="option-box card card--custom mb-4">
						<div className="card-body">
							<div
								className="color-preview-box h-200 rounded"
								style={{
									background: `${Color} url(${url}) ${bgPosition}/${bgSize} ${bgRepeat}`,
								}}
							/>
						</div>
					</div>
					<CodePreview data={codeString} />
				</div>
			</div>
		</>
	);
};

export default BackgroundImage;
