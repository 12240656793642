import React from "react";
import Typed from "react-typed";
import "./_banner.scss";

const textLines = [
	"CSS",
	"Flexbox",
	"Button",
	"Input",
	"Typography",
	"Glass UI",
];

const Banner = () => (
	<div className="banner-wrap">
		<div className="container">
			<h1 className="h1 large">
				Hello 👋
				<br /> welcome to <span className="text-primary">CSS Magics</span>
			</h1>
			<p>
				CSS Magics is <strong>focused</strong> on{" "}
				<strong>
					<span className="text-primary">
						<Typed strings={textLines} typeSpeed={70} backSpeed={60} loop />
					</span>
					Generator
				</strong>
				<br />
				CSS fast and delightful user experience to generate css code.
			</p>
		</div>
	</div>
);

export default Banner;
