/* eslint-disable eqeqeq */
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import InputNumber from "rc-input-number";

const BorderRadiusGenerator = ({ className, setCodeString }) => {
	const [borderRl, setBorderRl] = useState(0);
	const [borderRt, setBorderRt] = useState(0);
	const [borderRr, setBorderRr] = useState(0);
	const [borderRb, setBorderRb] = useState(0);
	const [radiusType, setRadiusType] = useState(false);
	const [sameRadius, setSameRadius] = useState(false);

	setCodeString(
		sameRadius
			? `-webkit-border-radius: ${borderRl}${radiusType ? "%" : "px"};
-moz-border-radius: ${borderRl}${radiusType ? "%" : "px"};
border-radius: ${borderRl}${radiusType ? "%" : "px"};`
			: borderRl == borderRt && borderRl == borderRr && borderRl == borderRb
			? `-webkit-border-radius: ${borderRl}${radiusType ? "%" : "px"};
-moz-border-radius: ${borderRl}${radiusType ? "%" : "px"};
border-radius: ${borderRl}${radiusType ? "%" : "px"};`
			: `-webkit-border-radius: ${borderRl}${
					radiusType ? "%" : "px"
			  } ${borderRt}${radiusType ? "%" : "px"} ${borderRr}${
					radiusType ? "%" : "px"
			  } ${borderRb}${radiusType ? "%" : "px"};
-moz-border-radius: ${borderRl}${radiusType ? "%" : "px"} ${borderRt}${
					radiusType ? "%" : "px"
			  } ${borderRr}${radiusType ? "%" : "px"} ${borderRb}${
					radiusType ? "%" : "px"
			  };
border-radius: ${borderRl}${radiusType ? "%" : "px"} ${borderRt}${
					radiusType ? "%" : "px"
			  } ${borderRr}${radiusType ? "%" : "px"} ${borderRb}${
					radiusType ? "%" : "px"
			  };`
	);

	return (
		<div className={`${className}`}>
			<div className="d-flex pb-2 justify-content-between">
				<Form.Check
					type="switch"
					label="Same on all sides"
					id="rgba-switch"
					checked={sameRadius}
					className="mb-0"
					onChange={(e) => setSameRadius(e.target.checked)}
				/>
				<div className="d-flex align-items-center pb-2">
					<label
						className={`form-check-label me-2 pe-1 ${
							radiusType ? "" : "fw-bold text-primary"
						}`}
						htmlFor="radiustype">
						PX
					</label>
					<div className="form-check form-switch">
						<input
							className="form-check-input"
							type="checkbox"
							role="switch"
							id="radiustype"
							checked={radiusType}
							onChange={(e) => setRadiusType(e.target.checked)}
						/>
					</div>
					<label
						className={`form-check-label ${
							radiusType ? "fw-bold text-primary" : ""
						}`}
						htmlFor="radiustype">
						Percentage
					</label>
				</div>
			</div>
			{sameRadius ? (
				<div className="row">
					<div className="col-md-4">
						<div className="form-group mb-md-0">
							<InputNumber
								min={0}
								onChange={(e) => setBorderRl(e)}
								value={borderRl}
							/>
						</div>
					</div>
				</div>
			) : (
				<div className="row">
					<div className="col-md-3">
						<div className="form-group mb-md-0">
							<Form.Label>Top Left</Form.Label>
							<InputNumber
								min={0}
								onChange={(e) => setBorderRl(e)}
								value={borderRl}
							/>
						</div>
					</div>
					<div className="col-md-3">
						<div className="form-group mb-md-0">
							<Form.Label>Top Right</Form.Label>
							<InputNumber
								min={0}
								onChange={(e) => setBorderRt(e)}
								value={borderRt}
							/>
						</div>
					</div>
					<div className="col-md-3">
						<div className="form-group mb-md-0">
							<Form.Label>Bottom Right</Form.Label>
							<InputNumber
								min={0}
								onChange={(e) => setBorderRr(e)}
								value={borderRr}
							/>
						</div>
					</div>
					<div className="col-md-3">
						<div className="form-group mb-md-0">
							<Form.Label>Bottom Left</Form.Label>
							<InputNumber
								min={0}
								onChange={(e) => setBorderRb(e)}
								value={borderRb}
							/>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default BorderRadiusGenerator;
