import React, { useState } from "react";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import Slider from "react-rangeslider";
import ColorPicker from "./ColorPicker";
import { borderStyle } from "../../Data/borderdata";

export const BorderComponent = ({ borderGen, setBorderGen }) => (
	<div className="row">
		<div className="col-md-6">
			<div className="form-group">
				<Form.Label>Column Count</Form.Label>
				<div className="d-flex align-items-center">
					<div className="w-100">
						<Slider
							min={1}
							max={10}
							value={borderGen.count}
							onChange={(e) => {
								setBorderGen((prevState) => ({
									...prevState,
									count: e,
								}));
							}}
						/>
					</div>
					<div className="text-dark fw-medium flex-shrink-0 w-60 text-end">
						{borderGen.count}
					</div>
				</div>
			</div>
		</div>
		<div className="col-md-6">
			<div className="form-group">
				<Form.Label>Column Gap</Form.Label>
				<div className="d-flex align-items-center">
					<div className="w-100">
						<Slider
							min={1}
							max={100}
							value={borderGen.gap}
							onChange={(e) => {
								setBorderGen((prevState) => ({
									...prevState,
									gap: e,
								}));
							}}
						/>
					</div>
					<div className="text-dark fw-medium flex-shrink-0 w-60 text-end">
						{borderGen.gap}px
					</div>
				</div>
			</div>
		</div>
		<div className="col-md-12">
			<div className="form-group">
				<Form.Label>Rule Width</Form.Label>
				<div className="d-flex align-items-center">
					<div className="w-100">
						<Slider
							min={0}
							max={30}
							value={borderGen.size}
							onChange={(e) => {
								setBorderGen((prevState) => ({
									...prevState,
									size: e,
								}));
							}}
						/>
					</div>
					<div className="text-dark fw-medium flex-shrink-0 w-60 text-end">
						{borderGen.size}px
					</div>
				</div>
			</div>
		</div>
		<div className="col-md-6">
			<div className="form-group">
				<Form.Label>Rule Style</Form.Label>
				<Select
					classNamePrefix="select"
					options={borderStyle}
					defaultValue={borderStyle[0]}
					menuPosition="fixed"
					menuShouldBlockScroll={true}
					onChange={(e) =>
						setBorderGen((prevState) => ({ ...prevState, style: e.value }))
					}
				/>
			</div>
		</div>
		<div className="col-md-6">
			<ColorPicker
				label="Rule Color"
				data={borderGen.color}
				setData={(e) => {
					setBorderGen((prevState) => ({
						...prevState,
						color: e,
					}));
				}}
			/>
		</div>
	</div>
);

const ColumnsGenerator = ({ className, setCodeString }) => {
	const [borderGen, setBorderGen] = useState({
		size: "1",
		style: "solid",
		color: "#ff7700",
		count: "2",
		gap: "16",
	});

	setCodeString(
		`${
			borderGen.size === 0
				? `-webkit-column-count: ${borderGen.count};
-moz-column-count: ${borderGen.count};
column-count: ${borderGen.count};
-webkit-column-gap: ${borderGen.gap}px;
-moz-column-gap: ${borderGen.gap}px;
column-gap: ${borderGen.gap}px;`
				: `-webkit-column-count: ${borderGen.count};
-moz-column-count: ${borderGen.count};
column-count: ${borderGen.count};
-webkit-column-gap: ${borderGen.gap}px;
-moz-column-gap: ${borderGen.gap}px;
column-gap: ${borderGen.gap}px;
-webkit-column-rule: ${borderGen.size}px ${borderGen.style} ${borderGen.color};
-moz-column-rule: ${borderGen.size}px ${borderGen.style} ${borderGen.color};
column-rule: ${borderGen.size}px ${borderGen.style} ${borderGen.color};`
		}`
	);

	return (
		<div className={`${className}`}>
			<BorderComponent borderGen={borderGen} setBorderGen={setBorderGen} />
		</div>
	);
};

export default ColumnsGenerator;
