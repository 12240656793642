import React from "react";
import { NavLink } from "react-router-dom";
import { IconChevronRight } from "@tabler/icons";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import { cssgeneratordata } from "../../Data/cssgeneratordata";
import "./_css-generator.scss";

// menu item start
export const MenuItems = ({ items, index }) => (
	<li key={index}>
		{items.children && items.children.length > 0 ? (
			<>
				<h4 className="h4">{items.title}</h4>
				<SubMenuList submenus={items.children} />
			</>
		) : (
			<>{items.title ? <h4 className="h4">{items.title}</h4> : ""}</>
		)}
	</li>
);
// menu item end
// sub menu item start
export const SubMenuList = ({ submenus }) => (
	<ul className="cm-generator-list">
		{submenus.map((submenu, index) => (
			<li key={index}>
				{submenu.link ? (
					<NavLink to={submenu.link}>
						{submenu.title ? (
							<>
								<span className="txt">{submenu.title}</span>
								<span className="arrow">
									<IconChevronRight />
								</span>
							</>
						) : (
							""
						)}
					</NavLink>
				) : (
					""
				)}
			</li>
		))}
	</ul>
);
// sub menu item end

const CssGenerator = () => (
	<>
		<InnerBanner title="CSS Generator" />
		<div className="css-generator-list-wrap">
			<div className="container">
				<div className="css-generator-list">
					<ul>
						{cssgeneratordata.map((menu, index) => (
							<MenuItems items={menu} key={index} />
						))}
					</ul>
				</div>
			</div>
		</div>
	</>
);

export default CssGenerator;
