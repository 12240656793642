import React, { useState } from "react";
import GeneratorTitleBlock from "../GeneratorTitleBlock/GeneratorTitleBlock";
import CodePreview from "../../../../components/CodePreview/CodePreview";
import RotateGenerator from "../../../../components/FormComponent/RotateGenerator";

const Rotate = () => {
	const [codeString, setCodeString] = useState("");

	return (
		<>
			<GeneratorTitleBlock title="Rotate" />

			<div className="generator-area">
				<div className="generator-area-options half-width">
					<h6 className="h6 pb-2">Options</h6>
					<div className="option-box card card--custom mb-4">
						<div className="card-body form-wrap">
							<RotateGenerator
								codeString={codeString}
								setCodeString={setCodeString}
							/>
						</div>
					</div>
					<CodePreview data={codeString} />
				</div>
				<div className="generator-area-preview half-width">
					<h6 className="h6 pb-2">Preview</h6>
					<div className="option-box card card--custom mb-4">
						<div className="card-body">
							<style
								// eslint-disable-next-line react/no-danger
								dangerouslySetInnerHTML={{
									__html: `.perspective-box{
										${codeString}
									}`,
								}}
							/>
							<div className="perspective-box">
								<div className="color-preview-box h-150 mx-w-300 mx-auto d-flex justify-content-center align-items-center bg-primary rounded opacity-box transition text-white p-3 text-ex-large">
									Rotate Box
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default Rotate;
