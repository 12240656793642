import React from "react";
import { Routes, Route } from "react-router-dom";
import { RoutesPath } from "../routesPath";

// Layout
import RouteWithSidebar from "../Layout/RouteWithSidebar";

// Pages
import Home from "./Home/Home";
import CssGenerator from "./CssGenerator/CssGenerator";
import BackgroundColor from "./CssGenerator/Components/BackgroundColor/BackgroundColor";
import BackgroundImage from "./CssGenerator/Components/BackgroundImage/BackgroundImage";
import Border from "./CssGenerator/Components/Border/Border";
import BorderRadius from "./CssGenerator/Components/BorderRadius/BorderRadius";
import BoxShadow from "./CssGenerator/Components/BoxShadow/BoxShadow";
import TextShadow from "./CssGenerator/Components/TextShadow/TextShadow";
import Opacity from "./CssGenerator/Components/Opacity/Opacity";
import BoxSizing from "./CssGenerator/Components/BoxSizing/BoxSizing";
import Outline from "./CssGenerator/Components/Outline/Outline";
import Column from "./CssGenerator/Components/Column/Column";
import Perspective from "./CssGenerator/Components/Perspective/Perspective";
import Rotate from "./CssGenerator/Components/Rotate/Rotate";
import Scale from "./CssGenerator/Components/Scale/Scale";
import Skew from "./CssGenerator/Components/Skew/Skew";
import Translate from "./CssGenerator/Components/Translate/Translate";
import Transition from "./CssGenerator/Components/Transition/Transition";

export default function App() {
	return (
		<Routes>
			<Route exact path={RoutesPath.Presentation.path} element={<Home />} />
			<Route
				exact
				path={RoutesPath.CssGenerator.path}
				element={<CssGenerator />}
			/>
			<Route element={<RouteWithSidebar />}>
				<Route
					exact
					path={RoutesPath.BackgroundColor.path}
					element={<BackgroundColor />}
				/>
				<Route
					exact
					path={RoutesPath.BackgroundImage.path}
					element={<BackgroundImage />}
				/>
				<Route exact path={RoutesPath.Border.path} element={<Border />} />
				<Route
					exact
					path={RoutesPath.BorderRadius.path}
					element={<BorderRadius />}
				/>
				<Route exact path={RoutesPath.BoxShadow.path} element={<BoxShadow />} />
				<Route
					exact
					path={RoutesPath.TextShadow.path}
					element={<TextShadow />}
				/>
				<Route exact path={RoutesPath.Opacity.path} element={<Opacity />} />
				<Route exact path={RoutesPath.BoxSizing.path} element={<BoxSizing />} />
				<Route exact path={RoutesPath.Outline.path} element={<Outline />} />
				<Route exact path={RoutesPath.Column.path} element={<Column />} />
				<Route
					exact
					path={RoutesPath.Perspective.path}
					element={<Perspective />}
				/>
				<Route exact path={RoutesPath.Rotate.path} element={<Rotate />} />
				<Route exact path={RoutesPath.Scale.path} element={<Scale />} />
				<Route exact path={RoutesPath.Skew.path} element={<Skew />} />
				<Route exact path={RoutesPath.Translate.path} element={<Translate />} />
				<Route
					exact
					path={RoutesPath.Transition.path}
					element={<Transition />}
				/>
			</Route>
		</Routes>
	);
}
