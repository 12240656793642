import React from "react";
import Select from "react-select";

const SelectPicker = ({ label, className, option, setdata }) => (
	<div className={`form-group ${className}`}>
		<label htmlFor="label" className="form-label">
			{label}
		</label>
		<Select
			classNamePrefix="select"
			options={option}
			defaultValue={option[0]}
			menuPosition="fixed"
			menuShouldBlockScroll={true}
			onChange={(e) => setdata(e.value)}
		/>
	</div>
);

export default SelectPicker;
