/* eslint-disable eqeqeq */
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Slider from "react-rangeslider";

const PerspectiveGenerator = ({ className, setCodeString }) => {
	const [opacity, setOpacity] = useState(50);
	const horizontalLabels = {
		0: "Low",
		300: "High",
	};

	setCodeString(`perspective: ${`${opacity}px`};`);

	return (
		<div className={`${className}`}>
			<div className="form-group">
				<Form.Label>perspective</Form.Label>
				<Slider
					min={0}
					max={300}
					value={opacity}
					labels={horizontalLabels}
					onChange={(e) => setOpacity(e)}
				/>
			</div>
		</div>
	);
};

export default PerspectiveGenerator;
