import React, { useState } from "react";
import Select from "react-select";
import Form from "react-bootstrap/Form";
// import InputNumber from "rc-input-number";
import Slider from "react-rangeslider";
import ColorPicker from "./ColorPicker";
import { borderStyle } from "../../Data/borderdata";

export const BorderComponent = ({ borderGen, setBorderGen }) => (
	<div className="row">
		<div className="col-md-12">
			<div className="form-group">
				<Form.Label>Thickness</Form.Label>
				<div className="d-flex align-items-center">
					<div className="w-100">
						<Slider
							min={1}
							max={50}
							value={borderGen.size}
							onChange={(e) => {
								setBorderGen((prevState) => ({
									...prevState,
									size: e,
								}));
							}}
						/>
					</div>
					<div className="text-dark fw-medium flex-shrink-0 w-60 text-end">
						{borderGen.size}px
					</div>
				</div>
			</div>
		</div>
		<div className="col-md-6">
			<div className="form-group">
				<Form.Label>Outline</Form.Label>
				<Select
					classNamePrefix="select"
					options={borderStyle}
					defaultValue={borderStyle[0]}
					menuPosition="fixed"
					menuShouldBlockScroll={true}
					onChange={(e) =>
						setBorderGen((prevState) => ({ ...prevState, style: e.value }))
					}
				/>
			</div>
		</div>
		<div className="col-md-6">
			<ColorPicker
				label="Color"
				data={borderGen.color}
				setData={(e) => {
					setBorderGen((prevState) => ({
						...prevState,
						color: e,
					}));
				}}
			/>
		</div>
		<div className="col-md-12">
			<div className="form-group">
				<Form.Label>Outline Offset</Form.Label>
				<div className="d-flex align-items-center">
					<div className="w-100">
						<Slider
							min={-100}
							max={100}
							value={borderGen.offset}
							onChange={(e) => {
								setBorderGen((prevState) => ({
									...prevState,
									offset: e,
								}));
							}}
						/>
					</div>
					<div className="text-dark fw-medium flex-shrink-0 w-60 text-end">
						{borderGen.offset}px
					</div>
				</div>
			</div>
		</div>
	</div>
);

const OutlineGenerator = ({ className, setCodeString }) => {
	const [borderGen, setBorderGen] = useState({
		size: "1",
		style: "solid",
		color: "#ff7700",
		offset: "0",
	});

	setCodeString(
		`${
			borderGen.offset === 0
				? `outline: ${borderGen.size}px ${borderGen.style} ${borderGen.color};`
				: `outline: ${borderGen.size}px ${borderGen.style} ${borderGen.color};
outline-offset: ${borderGen.offset}px;`
		}`
	);

	return (
		<div className={`${className}`}>
			<BorderComponent borderGen={borderGen} setBorderGen={setBorderGen} />
		</div>
	);
};

export default OutlineGenerator;
