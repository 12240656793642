/* eslint-disable eqeqeq */
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import InputNumber from "rc-input-number";
import ColorPicker from "./ColorPicker";

const BoxShadowGenerator = ({ className, setCodeString }) => {
	const [shadowX, setShadowX] = useState(0);
	const [shadowY, setShadowY] = useState(0);
	const [shadowBlur, setShadowBlur] = useState(0);
	const [shadowSpread, setShadowSpread] = useState(0);
	const [Color, setColor] = useState("#000000");
	const [shadowInset, setShadowInset] = useState(false);

	setCodeString(
		`-webkit-box-shadow: ${shadowX}px ${shadowY}px ${shadowBlur}px ${shadowSpread}px ${Color}${
			shadowInset ? " inset" : ""
		};
-moz-box-shadow: ${shadowX}px ${shadowY}px ${shadowBlur}px ${shadowSpread}px ${Color}${
			shadowInset ? " inset" : ""
		};
box-shadow: ${shadowX}px ${shadowY}px ${shadowBlur}px ${shadowSpread}px ${Color}${
			shadowInset ? " inset" : ""
		};`
	);

	return (
		<div className={`${className}`}>
			<div className="d-flex pb-2 justify-content-between">
				<Form.Check
					type="switch"
					label="Inset Shadow"
					id="inset-switch"
					checked={shadowInset}
					className="mb-0"
					onChange={(e) => setShadowInset(e.target.checked)}
				/>
			</div>
			<div className="row">
				<div className="col-6 col-md-6 col-lg-3">
					<div className="form-group">
						<Form.Label>X</Form.Label>
						<InputNumber onChange={(e) => setShadowX(e)} value={shadowX} />
					</div>
				</div>
				<div className="col-6 col-md-6 col-lg-3">
					<div className="form-group">
						<Form.Label>Y</Form.Label>
						<InputNumber onChange={(e) => setShadowY(e)} value={shadowY} />
					</div>
				</div>
				<div className="col-6 col-md-6 col-lg-3">
					<div className="form-group">
						<Form.Label>Blur</Form.Label>
						<InputNumber
							onChange={(e) => setShadowBlur(e)}
							value={shadowBlur}
						/>
					</div>
				</div>
				<div className="col-6 col-md-6 col-lg-3">
					<div className="form-group">
						<Form.Label>Spread</Form.Label>
						<InputNumber
							onChange={(e) => setShadowSpread(e)}
							value={shadowSpread}
						/>
					</div>
				</div>
				<div className="col-md-6">
					<ColorPicker
						label="Color"
						data={Color}
						setData={(e) => {
							setColor(e);
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default BoxShadowGenerator;
