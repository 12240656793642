import React, { useState } from "react";
import { IconMenu2, IconX } from "@tabler/icons";
import { Outlet } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import GeneratorSidebar from "../pages/CssGenerator/GeneratorSidebar";

const RouteWithSidebar = () => {
	const [open, setOpen] = useState(false);
	return (
		<div className="css-generator-area">
			<div className="generator-sidebar-area">
				<button
					type="button"
					className={`btn btn-sm btn-rounded btn-txt-icon sidebar-toggle btn-block ${
						open ? "btn-secondary" : "btn-primary"
					}`}
					aria-controls="example-collapse-text"
					aria-expanded={open}
					onClick={() => setOpen(!open)}>
					{open ? <IconX /> : <IconMenu2 />} {open ? "Close" : "Menu"}
				</button>
				<Collapse in={open}>
					<div id="example-collapse-text">
						<GeneratorSidebar />
					</div>
				</Collapse>
			</div>
			<div className="generator-preview-area">
				<Outlet />
			</div>
		</div>
	);
};

export default RouteWithSidebar;
