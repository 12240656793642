/* eslint-disable eqeqeq */
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Slider from "react-rangeslider";

const OpacityGenerator = ({ className, setCodeString }) => {
	const [opacity, setOpacity] = useState(50);
	const horizontalLabels = {
		0: "Low",
		100: "High",
	};

	setCodeString(
		`opacity: ${
			opacity === 100
				? "1"
				: opacity === 0
				? "0"
				: opacity === 10
				? "0.1"
				: opacity === 20
				? "0.2"
				: opacity === 30
				? "0.3"
				: opacity === 40
				? "0.4"
				: opacity === 50
				? "0.5"
				: opacity === 60
				? "0.6"
				: opacity === 70
				? "0.7"
				: opacity === 80
				? "0.8"
				: opacity === 90
				? "0.9"
				: opacity < 10
				? `0.0${opacity}`
				: `0.${opacity}`
		};`
	);

	return (
		<div className={`${className}`}>
			<div className="form-group">
				<Form.Label>opacity</Form.Label>
				<Slider
					min={0}
					max={100}
					value={opacity}
					labels={horizontalLabels}
					onChange={(e) => setOpacity(e)}
				/>
			</div>
		</div>
	);
};

export default OpacityGenerator;
