/* eslint-disable eqeqeq */
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import InputNumber from "rc-input-number";

const TranslateGenerator = ({ className, setCodeString }) => {
	const [translatex, setTranslatex] = useState(0);
	const [translatey, setTranslatey] = useState(0);
	const [translatez, setTranslatez] = useState(0);
	const [radiusType, setRadiusType] = useState(false);
	const [checked, setChecked] = useState(false);

	setCodeString(
		`${
			checked
				? `-webkit-transform: translate3d(${translatex}${
						radiusType ? "%" : "px"
				  }, ${translatey}${radiusType ? "%" : "px"}, ${translatez}${
						radiusType ? "%" : "px"
				  });
-moz-transform: translate3d(${translatex}${
						radiusType ? "%" : "px"
				  }, ${translatey}${radiusType ? "%" : "px"}, ${translatez}${
						radiusType ? "%" : "px"
				  });
-o-transform: translate3d(${translatex}${
						radiusType ? "%" : "px"
				  }, ${translatey}${radiusType ? "%" : "px"}, ${translatez}${
						radiusType ? "%" : "px"
				  });
-ms-transform: translate3d(${translatex}${
						radiusType ? "%" : "px"
				  }, ${translatey}${radiusType ? "%" : "px"}, ${translatez}${
						radiusType ? "%" : "px"
				  });
transform: translate3d(${translatex}${radiusType ? "%" : "px"}, ${translatey}${
						radiusType ? "%" : "px"
				  }, ${translatez}${radiusType ? "%" : "px"});`
				: `-webkit-transform: translate(${translatex}${
						radiusType ? "%" : "px"
				  }, ${translatey}${radiusType ? "%" : "px"});
-moz-transform: translate(${translatex}${
						radiusType ? "%" : "px"
				  }, ${translatey}${radiusType ? "%" : "px"});
-o-transform: translate(${translatex}${radiusType ? "%" : "px"}, ${translatey}${
						radiusType ? "%" : "px"
				  });
-ms-transform: translate(${translatex}${
						radiusType ? "%" : "px"
				  }, ${translatey}${radiusType ? "%" : "px"});
transform: translate(${translatex}${radiusType ? "%" : "px"}, ${translatey}${
						radiusType ? "%" : "px"
				  });`
		}`
	);

	return (
		<div className={`${className}`}>
			<div className="d-flex pb-2 justify-content-between">
				<div className="checkbox-2d3d">
					<input
						type="checkbox"
						id="checkbox2d"
						checked={checked}
						onChange={(e) => setChecked(e.target.checked)}
					/>
					<label htmlFor="checkbox2d">
						<span className="d2">2D</span>
						<span className="d3">3D</span>
					</label>
				</div>
				<div className="d-flex align-items-center pb-2">
					<label
						className={`form-check-label me-2 pe-1 ${
							radiusType ? "" : "fw-bold text-primary"
						}`}
						htmlFor="radiustype">
						PX
					</label>
					<div className="form-check form-switch">
						<input
							className="form-check-input"
							type="checkbox"
							role="switch"
							id="radiustype"
							checked={radiusType}
							onChange={(e) => setRadiusType(e.target.checked)}
						/>
					</div>
					<label
						className={`form-check-label ${
							radiusType ? "fw-bold text-primary" : ""
						}`}
						htmlFor="radiustype">
						Percentage
					</label>
				</div>
			</div>
			{checked ? (
				<div className="row">
					<div className="col-md-4">
						<div className="form-group">
							<Form.Label>Translate X </Form.Label>
							<InputNumber
								onChange={(e) => setTranslatex(e)}
								value={translatex}
							/>
						</div>
					</div>
					<div className="col-md-4">
						<div className="form-group">
							<Form.Label>Translate Y </Form.Label>
							<InputNumber
								onChange={(e) => setTranslatey(e)}
								value={translatey}
							/>
						</div>
					</div>
					<div className="col-md-4">
						<div className="form-group">
							<Form.Label>Translate Z </Form.Label>
							<InputNumber
								onChange={(e) => setTranslatez(e)}
								value={translatez}
							/>
						</div>
					</div>
				</div>
			) : (
				<div className="row">
					<div className="col-md-4">
						<div className="form-group">
							<Form.Label>Translate X </Form.Label>
							<InputNumber
								onChange={(e) => setTranslatex(e)}
								value={translatex}
							/>
						</div>
					</div>
					<div className="col-md-4">
						<div className="form-group">
							<Form.Label>Translate Y </Form.Label>
							<InputNumber
								onChange={(e) => setTranslatey(e)}
								value={translatey}
							/>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default TranslateGenerator;
