import React from "react";
import Browsers from "../../../../components/Browsers/Browsers";

const GeneratorTitleBlock = ({ title }) => (
	<>
		<h4 className="h4 pb-3">{title}</h4>
		<Browsers />
	</>
);

export default GeneratorTitleBlock;
