import React, { useState } from "react";
import GeneratorTitleBlock from "../GeneratorTitleBlock/GeneratorTitleBlock";
import CodePreview from "../../../../components/CodePreview/CodePreview";
import ColorPicker from "../../../../components/FormComponent/ColorPicker";

const BackgroundColor = () => {
	const [Color, setColor] = useState("#ff7700");
	const codeString = `background: ${Color};`;
	return (
		<>
			<GeneratorTitleBlock title="Background Color Generator" />

			<div className="generator-area">
				<div className="generator-area-options">
					<h6 className="h6 pb-2">Options</h6>
					<div className="option-box card card--custom mb-4">
						<div className="card-body form-wrap">
							<ColorPicker
								data={Color}
								setData={setColor}
								label="Color"
								className="mb-0"
							/>
						</div>
					</div>
					<CodePreview data={codeString} />
				</div>
				<div className="generator-area-preview">
					<h6 className="h6 pb-2">Preview</h6>
					<div className="option-box card card--custom mb-4">
						<div className="card-body">
							<div
								className="color-preview-box h-200 rounded"
								style={{ backgroundColor: `${Color}` }}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default BackgroundColor;
