import React from "react";
import "./_inner-banner.scss";

const InnerBanner = ({ title }) => (
	<>
		<div className="inner-banner-wrap">
			<div className="container">
				<h1 className="h2">{title}</h1>
			</div>
		</div>
	</>
);

export default InnerBanner;
